body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-radius-btn {
  border-radius: 0 !important; /* Remove border-radius */
}
.custom-bg {
  background-color: #4169E1; 
  color: white;/* Change to your desired color */
}
span{
  padding-right: 3%;
}
.footer-bg{
  background-color: #4169E1; 
  color:white
}
.no-hover-nav-link {
  color: white; /* Text color */
}
.no-hover-nav-link:hover,
.no-hover-nav-link:focus {
  color: white !important ;/* Same text color on hover and focus */
  text-decoration: none; /* Optionally remove underline on hover */
}
p{
  font-size: 14px;
}

.footer-links{
  line-height: 25px;
  li {
    a{
      text-decoration: none;
      color: white;
      font-size: 14px;
    }
  }
}
.title-font-size{
  font-size: 30px;
}
.rummy-img{
  background-image: url('../src/images/20230922_1318_650d46cbce4d4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}
.image-img{
  width: 150px;
  height: 200px;
}
.text{
  padding: 160px 10px;
  color: white;
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 16px;
  text-align: RIGHT;
  position: relative;
}
.icon-main{
  margin-right: 10px;
}

.download-btn{
  margin: 0 auto;
  margin-top: 32px;
  display: block;
  background: #fbd936;
  border: none;
  border-radius: 30px;
  
  position: relative;
  width: 100%;
  max-width: 250px;
  text-align: center;
  box-sizing: border-box;
  height: 60px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .9rem;
  cursor: pointer;
}
